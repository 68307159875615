import React from 'react'
import { useNavigate } from 'react-router-dom'

import { FaArrowRight, FaInstagram, FaTiktok } from 'react-icons/fa';
import InfoGif from "../Assets/info.gif"

import './Style/Footer.scss'




function Footer() {
    const Navigate = useNavigate()
    return (
        <div className='footer-container'>
            {/* <div className='gif'>
                <img src={InfoGif} alt="" />
            </div> */}
            <div className='contentBoxv2'>
                <ul>
                    <li onClick={() => Navigate("about")} >About</li>
                    <li onClick={() => Navigate("privacy")} >Term & Condition</li>
                    <li onClick={() => Navigate("privacy")} >Privacy Policiy</li>
                </ul>
            </div>
            {/* <div className='contentBox'>
                <div className='f1'><h1>Shop for all your quality fashion and electronics accessories.</h1>
                    <div className="btn"><button>Try Plugspace.io <FaArrowRight /></button></div>
                </div>
                <div className="f2">
                    <div  ><h3>Product</h3>
                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <ul>
                                <li>All Products</li>
                                <li>Homeware</li>
                                <li>Electronics</li>
                                <li>Outdoors</li>
                                <li>Man's</li>
                                <li>Women's</li>
                                <li>Kids</li>
                            </ul>
                            <ul>
                                <li>Musics</li>
                                <li>Sports</li>
                                <li>Stationary</li>
                                <li>Photography</li>
                                <li>Pets</li>
                                <li>Travels</li>

                            </ul>
                        </div>
                    </div>
                    <div>
                        <h3>Info</h3>

                        <ul>
                            <li onClick={() => Navigate("about")} >About</li>
                            <li onClick={() => Navigate("privacy")} >Term & Condition</li>
                            <li onClick={() => Navigate("privacy")} >Privacy Policiy</li>
                        </ul>
                    </div>

                    <div className='f3'>
                        <h3>Follow Us</h3>
                        <button className='insta'><FaInstagram size={20} /> <p>Instagram</p></button>
                        <button className='tiktok'><FaTiktok size={20} /> <p>Tiktok</p></button>
                    </div>
                </div>


            </div> */}
        </div>
    )
}

export default Footer