import React from 'react';

//css
import './Style/AboutUs.scss';

const AboutUs = () => {
  return (
    <div className="aboutus">
        <div className="aboutus-container">
      <h1 className='inline-block bg-gradient-to-r from-[#46A0E3] to-[#5845DF] bg-clip-text p-2 text-6xl font-extrabold text-transparent'>About Us</h1>
      <h2>Your Gateway to Global Drop Shipping Success</h2>
      <p>
        At Tiktok Finds, we empower entrepreneurs and businesses by offering a comprehensive drop shipping platform. Our services include seamless <strong>Shipping</strong>, efficient <strong>Buying</strong>, and <strong>Product Listing</strong> across popular marketplaces like <strong>TikTok, Shopify</strong>, and more. With access to over 10 global e-commerce giants such as <strong>Alibaba</strong> and <strong>Pinduoduo</strong>, you can source high-quality products and expand your market reach effortlessly.
      </p>
      <h3>Our Services</h3>
      <ul>
        <li><strong>Shipping:</strong> Fast and reliable shipping options tailored to your needs.</li>
        <li><strong>Buying:</strong> Easy purchasing solutions to streamline your inventory management.</li>
        <li><strong>Product Listing:</strong> Expert listing services on various marketplaces, including TikTok, Shopify, and others, ensuring maximum visibility and sales.</li>
      </ul>
      <h3>Why Choose Us?</h3>
      <ul>
        <li><strong>Global Sourcing:</strong> Access a wide range of products from trusted e-commerce platforms worldwide.</li>
        <li><strong>Seamless Integration:</strong> Our platform integrates smoothly with your existing systems, making it easy to manage your business.</li>
        <li><strong>Expert Support:</strong> Our team is here to assist you at every step, from sourcing to selling.</li>
      </ul>
      <h3>Get Started Today!</h3>
      <p>
        Join [Your Brand Name] and take your business to new heights. Whether you're a seasoned seller or just starting, our platform offers the tools and support you need to succeed in the competitive world of e-commerce.
      </p>
      </div>
    </div>
  );
};

export default AboutUs;
