import React from 'react';

//css
import './Style/AboutUs.scss';

const AboutUs = () => {
  return (
    <div className="aboutus">
      <div className="aboutus-container">
        <h1 className='inline-block bg-gradient-to-r from-[#46A0E3] to-[#5845DF] bg-clip-text p-2 text-6xl font-extrabold text-transparent'>Terms and Conditions | Privacy Policy</h1>
        <h2>Description</h2>
        <p>
          Plugspace.io: Empower your dropshipping business. Source from Alibaba, Tmall, AliExpress, and 1688 in one click. Connect your store, sell on multiple marketplaces, and manage everything from one dashboard. One-click multichannel selling, simplified.   </p>
        <h3>Plugspace.io Terms and Conditions</h3>
        <p>These Terms of Service ("Terms") govern your access to and use of our service, software, application, plug-in, component, functionality, or program created by Plugspace.io (collectively, "Services"). Please read these Terms carefully before using our website or any of our Services. By using Plugspace.io Services, you ("you" or "User") signify that you have read, understand, and agree to be legally bound by these Terms. Violation of any of the terms below will result in the termination of your account.  </p>
        {/* <ul>
          <li><strong>Shipping:</strong> Fast and reliable shipping options tailored to your needs.</li>
          <li><strong>Buying:</strong> Easy purchasing solutions to streamline your inventory management.</li>
          <li><strong>Product Listing:</strong> Expert listing services on various marketplaces, including TikTok, Shopify, and others, ensuring maximum visibility and sales.</li>
        </ul> */}
        <h3>Definitions</h3>
        <ul>
          <li>The terms “we”, “us” or “our” shall refer to Plugspace.io.</li>
          <li>The terms “you”, “your”, “user” or “customer” shall refer to any individual or entity who accepts this Agreement or purchases our Services.</li>
        </ul>
        <h3>Modification of Terms, Site, or Services</h3>
        <ul>
          <li>Plugspace.io reserves the right to modify these Terms at any time. We will post any changes on this page, and if the changes are significant, we will provide a prominent notice. Your continued use of the Services following such modifications constitutes your consent to the modified Terms.</li>
          <li>You are responsible for maintaining the security of your account and password. You are solely responsible for all activities that occur on your account.</li>
        </ul>
        <h3>Availability of Website/Services</h3>
        <ul>
          <li>We will make reasonable efforts to ensure our Services are available 24/7. However, you acknowledge that there may be times when our Site/Services are inaccessible due to equipment malfunctions, maintenance, repairs, or circumstances beyond our control.</li>
        </ul>
        <h3>Fees and Payment; Automatic Renewal</h3>
        <ul>
          <li>Your charges and fees are outlined in your order documentation.</li>
          <li>Plugspace.io may change prices and fees at any time, and these will be posted online.</li>
          <li>To ensure continued service, we offer auto-renewing subscriptions. You will be automatically charged for the Services based on the payment option and duration you selected during subscription, regardless of your usage of the Services.</li>
          <li>You can cancel anytime before the renewal date to avoid future charges.</li>
        </ul>
        <h3>Refund of Charges</h3>
        <ul>
          <li>All Plugspace.io services are prepaid. We do not provide refunds for cancellations by paying users, except as outlined below.</li>
          <li>Exceptionally, non-trial annual subscribers may request a refund within 30 days of being charged, receiving 80% of the annual fee back.</li>
        </ul>
        <h3>Limitation of Liability and Disclaimer of Representation and Warranties</h3>
        <ul>
          <li>The Services are available "AS-IS.". Plugspace.io makes no warranties or representations and disclaims all liability for the accuracy, completeness, availability, timeliness, security, or reliability of the Services or any content.</li>
          <li>In no event shall Plugspace.io be liable to you for any damages or loss of profits.</li>
        </ul>
        <h3>Important Notes</h3>
        <ul>
          <li>●Consider consulting with a legal professional to ensure your Terms and Conditions are comprehensive and compliant.</li>
          <li>Make sure your Terms and Conditions are easily accessible to users on your website.</li>
          <li>Clearly communicate your Terms and Conditions to users before they make a purchase or sign up.</li>
        </ul>
        <p>Let me know if you have any further revisions or additions!</p>
        <strong>support@plugspace.io</strong>



        <h3>Plugspace.io Privacy Policy</h3>
        <h3>Our Commitment to Your Privacy</h3>
        <p>
          Plugspace.io is committed to protecting your privacy. All information collected on our website and through our Services will be kept strictly confidential and will not be sold, reused, rented, disclosed, or loaned without your consent, except as required by law.
        </p>
        <h3>What Information Do We Collect?</h3>
        <p>
          We collect and use your personal information only as needed for legitimate business purposes and to provide you with our Services. This information may include:
        </p>
        <ul>
          <li><strong>Basic Personal Information:</strong> Name, address, telephone number, email address, company name, billing and payment information. This data is collected directly from you when you create an account or purchase our Services. You can review and edit this information at any time by logging into your account. </li>
          <li><strong>Website Usage Information:</strong> When you visit our site, we may automatically collect information such as your IP address and web browser details. This data is used to analyze website traffic and improve our Services. </li>
          <li><strong>Cookies:</strong> We may use cookies to enhance your browsing experience and personalize content. You can disable cookies in your browser settings, but this may impact certain features of our site. For more information, please see our Cookie </li>
        </ul>
        <h3>How Do We Use Your Data?</h3>
        <p>
          We use your data for the following purposes:
        </p>
        <ul>
          <li>To provide, maintain, and improve our Services.</li>
          <li>To process your transactions and manage your account.</li>
          <li>To communicate with you about your account, our Services, and promotional offers (if you have opted in to receive them).</li>
          <li>To comply with legal obligations.</li>
        </ul>
        <h3>How Do We Protect Your Information?</h3>
        <p>
          We take the security of your information seriously. We implement industry-standard security measures to protect your data from unauthorized access, alteration, or disclosure.
        </p>
        <h3>How Long Is Your Data Saved?  </h3>
        <p>
          We will retain your personal information for as long as necessary to provide you with our Services and fulfill our legal obligations. If you terminate your account, we will delete your personal information within a reasonable timeframe, unless otherwise required by law.
        </p>
        <h3>How to Request Data Deletion  </h3>
        <p>
          If you wish to have your data deleted sooner, please contact us at [contact email address]. We will process your request as soon as possible.
        </p>
        <h3>Changes and Updates</h3>
        <p>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page, and if the changes are significant, we will provide a prominent notice.
        </p>
        <strong>Contact Us” support@plugspace.io</strong>
      </div>
    </div>
  );
};

export default AboutUs;
