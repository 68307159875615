import React, { useEffect, useState } from 'react'

// ANT-D :
import { Button, Upload } from 'antd'
import ImgCrop from 'antd-img-crop';

// APIs :
import { AddProductAPI, SavePublishReadyProductAPI } from '../../../../Api/products';

// Helper :
import toast from 'react-hot-toast';
import ReactQuill from 'react-quill';

// CSS :
import "./AddProduct.scss"
import { GetSpecificSavedAliexpressProductAPI } from '../../../../Api/ThirdPartyServices';



var toolbarOptions = [
    ['bold', 'italic'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],
    ['image', 'video'],                      // text direction

    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

];
var toolbarOptionsMobile = [
    ['bold', 'italic'],
    [{ 'font': [] }],
    ['image'],
];

const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'code-block',
    'align',
    'direction',
    'color',
    'background',
    'script',
    'super',
    'sub',
];

const modules = {
    toolbar: toolbarOptions
};
const Index = ({ closePage, selectedId }) => {

    const [formData, setFormData] = useState({
        title: "",
        price: "",
        originalPrice: "",
        description: "",
        brand: "",
        size: "",
        weight: "",
        quantity: "",

        currency: "",
        propertiesList: {},
        propertiesImages: {}
    })
    const [Sizes, setSizes] = useState([])
    const [selectedSizes, setSelectedSizes] = useState([])
    const [selectedColors, setSelectedColors] = useState([])

    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)


    const enteringFormData = (event) => {
        let { name, value } = event.target;

        setFormData({
            ...formData,
            [name]: value
        })
    }

    const enteringFile = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    }
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const imgWindow = window.open(src);
        imgWindow?.document.write(`<img src="${src}" alt="preview" />`);
    };

    const selectingProperties = async (type, value) => {
        if (type == "size") {
            const FindElement = selectedSizes.find(el => el == value)
            if (FindElement) {
                let Result = selectedSizes.filter(el => el != value)
                setSelectedSizes(Result)
            } else {
                setSelectedSizes([...selectedSizes, value])
            }
        } else {
            const FindElement = selectedColors.find(el => el == value)
            if (FindElement) {
                let Result = selectedColors.filter(el => el != value)
                setSelectedColors(Result)
            } else {
                setSelectedColors([...selectedColors, value])
            }
        }
    }

    const SaveProduct = async () => {
        setLoading(true)
        let Payload = formData
        Payload.id = selectedId
        Payload.selectedColors = selectedColors
        Payload.selectedSizes = selectedSizes
        // let Payload = new FormData()
        // Object.keys(formData).map(key => {
        //     Payload.append(key, formData[key])
        // })
        // fileList.map(file => {
        //     Payload.append("file", file.originFileObj)
        // })

        let res = await SavePublishReadyProductAPI(Payload)
        if (res.error != null) {
            toast.error(res.error)
        } else {
            toast.success(res.data.message)
            closePage()
        }
        setLoading(false)
    }

    const gettingProductDetails = async () => {
        let res = await GetSpecificSavedAliexpressProductAPI(selectedId)
        if (res.error != null) {
            toast.error(res?.error)
        } else {
            // console.log("=========== xxxxxxxxxxxxxxxxxxxxxx ===========", res?.data);
            let ProductData = res?.data?.result?.productinfo
            setFormData({
                title: ProductData?.title,
                price: 0,
                originalPrice: ProductData?.orginal_price,
                description: ProductData?.desc,
                brand: ProductData?.brand || ProductData?.nick,
                size: ProductData?.item_size,
                weight: ProductData?.item_weight,
                quantity: "",
                image: ProductData?.pic_url,
                type: "aliexpress",
                currency: ProductData?.currency_code,
                propertiesImages: ProductData?.props_img || {},
                propertiesList: ProductData?.props_list || {}
            })
            let FindSizes = []
            if (ProductData?.props_list && Object.keys(ProductData?.props_list).length >= 1) {
                Object.keys(ProductData?.props_list).map(key => {
                    if (String(key)[0] == "5") {
                        FindSizes.push(key)
                    }
                })
                setSizes(FindSizes)
            }
        }
    }
    useEffect(() => {
        if (selectedId) {
            gettingProductDetails()
        } else {
            toast.error("Inavlid Product ID")
            closePage()
        }
    }, [])

    return (
        <>
            <div className='AddProductContainer' style={{ padding: '0px' }}>

                <div className="title">
                    <h2 className="inline-block bg-gradient-to-r from-[#46A0E3] to-[#5845DF] bg-clip-text p-2 text-6xl font-extrabold text-transparent">
                        Add Product
                    </h2>
                </div>
                <div className="form">
                    <div className='flex flex-col gap-8 bg-white p-6 rounded-2xl max-w-[600px] m-auto'>
                        <div className="m-auto">
                            {/* <ImgUploader  /> */}
                            <ImgCrop rotationSlider>
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    // multiple={true}
                                    onChange={enteringFile}
                                    disabled
                                    onPreview={onPreview}
                                    className="custom-upload" // Apply the custom class
                                >
                                    {
                                        formData?.image ?
                                            <img src={formData?.image} alt="" style={{ width: "98%", height: "98%", objectFit: "cover", borderRadius: ".4rem" }} />
                                            :
                                            fileList.length <= 0 && '+ Upload'
                                    }
                                </Upload>
                            </ImgCrop>
                        </div>

                        <div className='flex flex-col gap-2'>
                            <div className="flex gap-2 items-center">
                                <div className="flex flex-col gap-1 w-full">
                                    <p className='ml-3'>Product Name </p>
                                    <input
                                        className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                                        id="name"
                                        type="text"
                                        name='title'
                                        value={formData?.title}
                                        onChange={enteringFormData}
                                        placeholder="Name"
                                    />
                                </div>
                                <div className="flex flex-col gap-1 w-full">
                                    <p className='ml-3'>Brand </p>
                                    <input
                                        className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                                        id="name"
                                        type="text"
                                        name='brand'
                                        value={formData?.brand}
                                        onChange={enteringFormData}
                                        placeholder="quantity"
                                    />
                                </div>
                            </div>

                            <div className="flex gap-2 items-center">
                                <div className="flex flex-col gap-1 w-full">
                                    <p className='ml-3'> Price <b> {formData && formData?.currency} </b> </p>
                                    <input
                                        className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                                        id="name"
                                        type="text"
                                        name='price'
                                        value={formData?.price}
                                        onChange={enteringFormData}
                                        placeholder="Sales Price"
                                    />
                                </div>

                                <div className="flex flex-col gap-1 w-full">
                                    <p className='ml-3'> Original Price <b> {formData && formData?.currency} </b> </p>
                                    <input
                                        className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                                        id="name"
                                        type="text"
                                        name='originalPrice'
                                        value={formData?.originalPrice}
                                        onChange={enteringFormData}
                                        placeholder="OriginalPrice"
                                    />
                                </div>
                            </div>
                            <div className="flex gap-2 items-center">
                                {/* <div className="flex flex-col gap-1 w-full">
                                    <p className='ml-3'> Size </p>
                                    <input
                                        className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                                        id="name"
                                        type="text"
                                        name='size'
                                        value={formData?.size}
                                        onChange={enteringFormData}
                                        placeholder="Size"
                                    />
                                </div> */}

                                <div className="flex flex-col gap-1 w-full">
                                    <p className='ml-3'> Weight </p>
                                    <input
                                        className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                                        id="name"
                                        type="text"
                                        name='weight'
                                        value={formData?.weight}
                                        onChange={enteringFormData}
                                        placeholder="Weight"
                                    />
                                </div>
                            </div>
                            {/* <div className="flex gap-2 items-center">
                                <div className="flex flex-col gap-1 w-full">
                                    <p className='ml-3'> Quantity </p>
                                    <input
                                        className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                                        id="name"
                                        type="number"
                                        name='quantity'
                                        value={formData?.quantity}
                                        onChange={enteringFormData}
                                        placeholder="Quantity"
                                    />
                                </div>

                                <div className="flex flex-col gap-1 w-full">
                                    <p className='ml-3'> Description </p>
                                    <input
                                        className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                                        id="name"
                                        type="text"
                                        name='description'
                                        value={formData?.description}
                                        onChange={enteringFormData}
                                        placeholder="Details"
                                    />
                                </div>
                            </div> */}
                            <div className="flex flex-col gap-1 w-full">
                                <p className='ml-3'> Sizes </p>
                                {
                                    Sizes && Sizes?.length >= 1 ?
                                        <div className="colorsBox">
                                            {
                                                Sizes.map(data => {
                                                    return (
                                                        <div key={data} className={`sizeBox`} style={selectedSizes.includes(data) ? { backgroundColor: "green" } : {}} onClick={() => selectingProperties("size", data)} >
                                                            {formData?.propertiesList[data].slice(5)}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <> No Colors </>
                                }
                            </div>
                            <div className="flex flex-col gap-1 w-full">
                                <p className='ml-3'> Colors </p>
                                {
                                    formData && formData?.propertiesImages && Object.keys(formData?.propertiesImages)?.length >= 1 ?
                                        <div className="colorsBox">
                                            {
                                                Object.keys(formData?.propertiesImages).map(data => {
                                                    return (
                                                        <div key={data} className="colorBox" style={selectedColors.includes(data) ? { border: "2px solid green" } : {}} onClick={() => selectingProperties("color", data)}>
                                                            <img src={formData?.propertiesImages[data]} alt="ERROR" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <> No Colors </>
                                }
                            </div>
                            <div className="flex flex-col gap-1 w-full" style={{ paddingBottom: "2rem" }}>
                                <p className='ml-3'> Description </p>
                                <ReactQuill theme='snow' formats={formats} modules={modules} style={{ height: "250px" }} className='contentPara' value={formData?.description} name="description" />
                            </div>
                        </div>

                        <div className='btnn flex gap-2'>
                            <Button
                                loading={loading}
                                type="submit"
                                onClick={SaveProduct}
                                className="mx-auto mt-4 flex w-full items-center justify-center gap-2 rounded-full bg-[#1469F3] px-4 py-2 font-medium text-white hover:opacity-80 h-[40px]">

                                <label htmlFor="">Save</label>
                            </Button>

                            <Button
                                onClick={closePage}
                                type="submit"
                                className="mx-auto mt-4 flex w-full items-center justify-center gap-2 rounded-full bg-[#f36464d9] px-4 py-2 font-medium text-white hover:opacity-80 h-[40px]">
                                <label htmlFor="">Cancel</label>
                            </Button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Index