
import { Routes, Route } from 'react-router-dom'
import Signup from './components/SignupPage';
import Footer from './components/Footer';
import Login from './components/LoginPage';
import Home from './components/Home';
import NavBar from './components/NavBar';
import EditProfile from './components/EditProfile';
import { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';
import { GetProfileAPI } from './Api/auth';
import { userDataActions } from "./Redux/Slice/userData/index"
import { useDispatch } from 'react-redux';
import Dashboard from './Pages/Dashboard/Dashboard';
import Plans from './components/Plans';
import Products from "./Pages/Products/Index"
import ViewAliExpressProducts from "./Pages/ViewAliExpressProducts/Index"
import View1688Products from "./Pages/View1688Products/Index"
import Pricing from './components/Pricing'
import AboutUs from './components/AboutUs';
import PrivacyPolicy from './components/Privacy';
import HowWorks from './components/HowWorks';
import 'quill/dist/quill.snow.css'; // Add css for snow theme


const ClientRoutes = () => {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path='' element={<Home />} />
        <Route path='profile' element={<EditProfile />} />
        <Route path='login' element={<Login />} />
        <Route path='signup' element={<Signup />} />
        <Route path='plans' element={<Pricing />} />
        <Route path='pricing' element={<Plans />} />
        <Route path='about' element={<AboutUs />} />
        <Route path='privacy' element={<PrivacyPolicy />} />
        <Route path='howwork' element={<HowWorks />} />
        <Route path='products' element={<Products />} />
        <Route path='viewAliExpressProducts' element={<ViewAliExpressProducts />} />
        <Route path='view1688Products' element={<View1688Products />} />
      </Routes>
      <Footer />
    </>
  )
}

function App() {
  let Dispatch = useDispatch();

  const gettingUserProfile = async () => {
    let res = await GetProfileAPI()
    if (res.error != null) {
      console.log(res.error);
    } else {
      let result = res.data?.data?.result
      Dispatch(userDataActions.setUserData(result))
    }
  }
  useEffect(() => {
    let Token = localStorage.getItem("token")
    if (Token) {
      gettingUserProfile()
    }
  }, [])

  return (
    <>
      <Toaster />
      <Routes>
        <Route path='/*' element={<ClientRoutes />} />
        <Route path='dashboard/*' element={<Dashboard />} />
      </Routes>
    </>
  );
}

export default App;
