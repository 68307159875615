import React, { useEffect, useState } from 'react'

// ANT-D :
import { Image, Table, Button } from "antd";

// Assets | ICONS :
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

// APIs :
import { DeleteProductAPI } from '../../../../Api/products';
import { GetSavedAliexpressProductsAPI } from '../../../../Api/ThirdPartyServices';
// Helpers :
import toast from 'react-hot-toast';
import ImgURL from "../../../../Utils/GenImgUrl";

// CSS :
import "./Index.scss";





const Index = ({ setCurrentPage, setSelectedId }) => {

    const [AllProducts, setAllProducts] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            title: 'Image',
            render: (_, data) => <Image width={70} src={data?.pic_url} style={{ borderRadius: ".3rem", overflow: "hidden" }} />
        },
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
            render: (_, data) => data?.title?.split(" ").slice(0, 4).join(" ")
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: "400px",
            render: (_, data) => data?.title
        },
        // {
        //     title: 'Quantity',
        //     dataIndex: 'quantity',
        //     key: 'quantity',
        // },
        {
            title: 'Original Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: (_, data) => {
                return (
                    <div style={{ display: 'flex', gap: '1rem', }}>
                        <EditOutlined onClick={() => EditProduct(data?._id)} style={{ cursor: "pointer", fontSize: "1rem" }} />
                        <DeleteOutlined onClick={() => DeleteProduct(data?._id)} style={{ cursor: "pointer", color: "red", fontSize: "1rem" }} />
                    </div>
                )
            }
        },
    ];

    let EditProduct = (id) => {
        setSelectedId(id)
        setCurrentPage("edit")
    }

    let DeleteProduct = async (id) => {
        setLoading(true)
        let res = await DeleteProductAPI(id)
        if (res.error != null) {
            toast.error(res.error)
        } else {
            toast.success(res.data?.message)
            setRefresh(!refresh)
        }
        setLoading(false)
    }

    let GettingAllProducts = async () => {
        setLoading(true)
        let res = await GetSavedAliexpressProductsAPI()
        if (res.error != null) {
            toast.error(res.error)
        } else {
            setAllProducts(res.data?.result || [])
        }
        setLoading(false)
    }
    useEffect(() => {
        GettingAllProducts()
    }, [])

    return (
        <>
            <div className='flex flex-col gap-6 p-4 bgColor overflow-y-scroll h-full'>
                <div className='flex justify-between items-center'>
                    <h2 className="inline-block bg-gradient-to-r from-[#46A0E3] to-[#5845DF] bg-clip-text p-2 text-6xl font-extrabold text-transparent">
                        PRODUCTS LIST
                    </h2>
                    {/* <Button className='btn' onClick={() => setCurrentPage("add")} > ADD PRODUCT </Button> */}
                </div>
                <div className="CustomTable">
                    <Table loading={loading} columns={columns} dataSource={AllProducts} />
                </div>
            </div>
        </>
    )
}

export default Index